import { createSignal, createMemo, type Accessor } from "solid-js";
import {  useAction } from "@solidjs/router";
import { createStore } from "solid-js/store";
import { addToCartAction } from "~/services/cart";
import { OrderProductTypes } from "~/services/roma-api/cart/types-productOrdering";

export const createChunkedRequest = (
  list: Accessor<any[]>,
  fetchUrl: string,
  limit: number = 10
) => {
  const [completionState, setCompletionState] = createSignal<number>(0);
  const [actionIsPending, setActionIsPending] = createSignal<boolean>(false);
  const [pendingPosition, setPendingPosition] = createSignal<number>(0);
  const [skus, setSkus] = createStore<Array<boolean | null>>(
    new Array(list().length).fill(null)
  );

  const addToCart = useAction(addToCartAction);

  const chunkedList = createMemo(() => {
    const arr = list();
    const chunked = [];
    for (let i = 0; i < arr.length; i += limit) {
      chunked.push(arr.slice(i, i + limit));
    }
    return chunked;
  });

  const request = async () => {
    let status = "";
    setActionIsPending(true);
    setCompletionState(0);

    try {
      for (let i = 0; i < chunkedList().length; i++) {
        setPendingPosition(i * limit);
        const chunk = chunkedList()[i];

        try {
          // const response = await fetch(fetchUrl, {
          //   method: "POST",
          //   headers: {
          //     Authorization: `Bearer ${session()?.token}`,
          //     "Content-Type": "application/json",
          //   },
          //   body: JSON.stringify(chunk),
          // });
          
          // ? changed to use addToCartAction
          // TODO - test more thoroughly, force some mid-request errors
          const data = await addToCart(chunk as OrderProductTypes[]);
          let success = !!data && Array.isArray(data);

          if (success) {
            // Update skus status for this chunk
            for (let j = i * limit; j < i * limit + chunk.length; j++) {
              setSkus(j, success);
            }
            if (status === "FAILURE") {
              status = "PARTIAL";
            } else if (status === "") {
              status = "SUCCESS";
            }
          } else {
            if (status === "SUCCESS") {
              status = "PARTIAL";
            } else if (status === "") {
              status = "FAILURE";
            }
          }
        } catch (error) {
          console.error("Chunk request error:", error);
          // Mark all items in failed chunk as failed
          for (let j = i * limit; j < i * limit + chunk.length; j++) {
            setSkus(j, false);
          }
          if (status === "SUCCESS") {
            status = "PARTIAL";
          } else if (status === "") {
            status = "FAILURE";
          }
        }

        setCompletionState((i + 1) / chunkedList().length);
      }
    } catch (error) {
      console.error("Request error:", error);
      status = status === "SUCCESS" ? "PARTIAL" : "FAILURE";
    }

    setActionIsPending(false);
    return {
      status,
      result: skus,
      errors: Array.from(skus)
        .map((success, index) => (success === false ? index : null))
        .filter((i) => i !== null),
    };
  };

  return {
    completionState,
    requesting: actionIsPending,
    request,
    skus,
    actionIsPending,
    pendingPosition,
  };
};
