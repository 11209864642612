import { type Component, Show } from "solid-js";
import { Progress } from "@kobalte/core";

type ProgressBarProps = {
  val: number;
  label?: string;
  height?: number;
  rootClass?: string;
  trackClass?: string;
  fillClass?: string;
};

export const ProgressBar: Component<ProgressBarProps> = (props) => {
  const rootClass = () => {
    const base = "flex flex-col gap-1 w-full text-sm";
    return `${base} ${props.rootClass ?? ""}`;
  };

  const trackClass = () => {
    const base = `${
      props.height ? `h-[${props.height}px]` : "h-3"
    } bg-roma-grey rounded-full overflow-hidden`;
    return `${base} ${props.trackClass ?? ""}`;
  };

  const fillClass = () => {
    const base =
      "w-[var(--kb-progress-fill-width)] h-full bg-roma-blue transition-all duration-500 rounded-r-full";
    return `${base} ${props.fillClass ?? ""}`;
  };

  return (
    <Progress.Root value={props.val} class={rootClass()}>
      <Show when={props.label}>
        <div class="flex justify-between text-sm">
          <Progress.Label>{props.label}</Progress.Label>
          <Progress.ValueLabel />
        </div>
      </Show>
      <Progress.Track class={trackClass()}>
        <Progress.Fill class={fillClass()} />
      </Progress.Track>
    </Progress.Root>
  );
};
